<template>
  <div class="flex items-center">
    <VImage v-if="src" :src="src" :name="name" class="mr-2" />

    {{ name }}
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import VImage from "@/components/VImage";

export default {
  components: {
    VImage
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Composables
    const { getAvatarURL } = useDisplay();

    // Computed
    const src = computed(() => {
      return getAvatarURL(props.item?.avatar?.view_path);
    });

    const name = computed(() => {
      const { firstname, lastname } = props.item ?? {};

      if (firstname && lastname) {
        return `${firstname} ${lastname}`;
      }

      return "";
    });

    return {
      src,
      name
    };
  }
};
</script>
